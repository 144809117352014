import { PermissionsScope } from '@sbiz/business';

import { AuthUser } from './types';

export function getCompanyId(authUser: AuthUser) {
  const { companies, targetCompanyId } = authUser;

  if (companies.length > 1) {
    return targetCompanyId;
  }

  if (companies?.length === 1) {
    return companies.at(0)?._id;
  }
}

export function isEditor(user: AuthUser, scope: PermissionsScope) {
  return Boolean(user?.permissions?.[scope]?.write);
}
